/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        $(document).foundation(); // Foundation JavaScript
      
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {},
      finalize: function() {}
    },
    'page_template_template_flexible': {
      init: function() {},
      finalize: function() {      

        ///////////// Text Cycle/Slider //////////////

        var textSlider = $('.text-cycle-slider');
        
        textSlider.slick({
          dots: false,
          arrows: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          //fade: true,
          autoplay: true,
          autoplaySpeed: 2000,
        });

        textSlider.slick("slickPause");

        textSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
          console.log( 'v3' );
          resetProgressBars();
          var current = nextSlide !== undefined ? nextSlide : 0; // Use nextSlide in beforeChange
          fillProgressBar(current);
        });
       
        function resetProgressBars() {
          $('.progress-bar-fill').css('width', '0');
        }

        function fillProgressBar(index) {
            $('.progress-bar-fill').eq(index).animate({ width: '100%' }, 2000, 'linear');
        }

        textSlider.slick('slickGoTo', 0);

        textSlider.slick("slickPlay");

        ///////////// Card Cycle/Slider //////////////

        var cardSlider = $('.card-cycle-slider');

        cardSlider.slick({
          //dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        
        ///////////// Video Cycle/Slider ////////////        

        var videoSlider = $('.testimonial-cycle-slider');

        videoSlider.on('init beforeChange', function(event, slick, currentSlide, nextSlide){
          console.log( 'v15' );
          resetVideoProgressBars();
          var current = nextSlide !== undefined ? nextSlide : 0; // Use nextSlide in beforeChange
          console.log(current);
          fillVideoProgressBar(current);
        });

        videoSlider.slick({
          dots: false,
          arrows: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          fade: true,
          autoplaySpeed: 3000,
        });

        videoSlider.slick("slickPause");

        function resetVideoProgressBars() {
          $('.progress-bar-fill').css('width', '0');
        }

        //function fillVideoProgressBar(index) {
        //    $('.progress-bar-fill').eq(index).animate({ width: '100%' }, 3000, 'linear');
        //}

        var isProgressBarFilling = false; // Track if a progress bar is animating

        function fillVideoProgressBar(index) {
          if (isProgressBarFilling) {
              return; // Prevent starting a new animation if one is running
          }

          isProgressBarFilling = true; // Set the flag to indicate animation is active

          $('.progress-bar-fill')
          .eq(index)
          .animate({ width: '100%' }, 3000, 'linear', function () {
              isProgressBarFilling = false; // Reset the flag when animation completes
          });
        }
        
        videoSlider.slick('slickGoTo', 0);
        
        setTimeout(function() {
          videoSlider.slick("slickPlay");
        },100);

        $(document).ready(function () {
          const videoSlider = $('.testimonial-cycle-slider');
      
          $('.progress-bar[data-slide]').on('click', function () {
              $('.progress-bar-fill').stop();
              isProgressBarFilling = false; // Reset the flag
              resetVideoProgressBars();
              const slideIndex = parseInt($(this).data('slide'), 10);
              if (!isNaN(slideIndex)) {
                  videoSlider.slick('slickGoTo', slideIndex);
              } else {
                  console.error('Invalid slide index:', slideIndex);
              }
          });
        });

        // Trigger the init event to start the first progress bar
        //$('.video-cycle-slider').slick('slickGoTo', 0);


        ///////////// Team Slider //////////////

        $('.team-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.